/**
 * Obtener el valor del enum  ==> EPermissions.CreateProduct
 * Obtener el nombre del enum ==> EPermissions[EPermissions.CreateProduct]
 */

/**
 * IMPLEMENTACIÓN
 * 
 * En .ts   ==>  get ePermissions(): typeof EPermissions { return EPermissions }
 * En .html ==>  ePermissions.CreateProduct
 */


/**
 * Id´s de los estatus de las ordenes
 */
export enum EPermissions {

    //Módulo y menú entrada de inventario.
    Input = 1,

    //Módulo y menú salida de inventario.
    Output = 2,

    //Módulo y menú reporte.
    Reports = 3,

    //Módulo y menú configuración.
    Configuration = 4,

    //Módulo y menú configuración de producto.
    ProductConfiguration = 5,

    //Confirmar entrada de unidades al invnetario.
    ConfirmInput = 6,

    //Confirmar salida de unidades del inventario.
    ConfirmOutput = 7,

    //Crear producto.
    CreateProduct = 8,

    //Editar producto.
    EditProduct = 9,

    //Obtener el rol o la lista de roles
    RolesList = 10,

    //Menú: Configuración/Usuarios
    UserConfiguration = 11,

    //Crear usuario
    CreateUser = 12,

    //Editar usuario
    EditUser = 13,

    //Obtener el usuario o la lista de usuarios
    UserList = 14,

    //Permite consultar y dar salidas a los productos descontinuados
    DiscontinuedProducts = 15,

    //Super permiso solo para el perfil Sistemas T.I.
    ITSuperPermit = 16,

    //Permiten la autorización de entradas/salidas que lo requieran.
    AuthorizeInputAndOutput = 17

}