import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGrupoCenturionService } from './api-grupo-centurion.service';
import { ReqForgotPassword, ReqLogin, ReqResetPassword, ReqValidateLinkForgotPassword } from '../interfaces/Auth';
import { ActiveSessionService } from './active-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly apiGC = inject(ApiGrupoCenturionService)
  private readonly activeSessionService = inject(ActiveSessionService)

  public Login(dataRequest: ReqLogin): Observable<any> {
    return this.apiGC.post(`auth/login`, dataRequest)
  }

  public ForgotPassword(dataRequest: ReqForgotPassword): Observable<any> {
    return this.apiGC.post(`auth/forgot_password`, dataRequest)
  }

  public ValidateLinkForgotPassword(dataRequest: ReqValidateLinkForgotPassword): Observable<any> {
    return this.apiGC.post(`auth/validate_link_forgot_password`, dataRequest)
  }
  
  public ResetPassword(dataRequest: ReqResetPassword): Observable<any> {
    return this.apiGC.post(`auth/reset_password`, dataRequest)
  }

  public ListPermissions(): Observable<any> {
    return this.apiGC.post(`auth/permissions`, this.activeSessionService.JsonUUID() )
  }
}
