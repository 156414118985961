import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActiveSessionService {

  private readonly tokenMC = 'TokenGC';
  private readonly userUUIDMC = 'UserUUIDMC';

  private router = inject(Router)

  private readonly localStorageService = inject(LocalStorageService)

  Login(token: string, uuid: string): boolean {
    try{
      
      this.localStorageService.set(this.tokenMC, token)
      this.localStorageService.set(this.userUUIDMC, uuid)

      return true
    }catch{
      return false
    }
  }
 
  GetToken(): string {
    return this.localStorageService.get(this.tokenMC);
  }


  GetUUID(): string {
    return this.localStorageService.get(this.userUUIDMC);
  }

  JsonUUID(): any {
    return { UUID: this.localStorageService.get(this.userUUIDMC) } 
  }

  SessionIsActive() : boolean {
    try{
      return (this.localStorageService.check(this.tokenMC) && this.localStorageService.check(this.userUUIDMC))
    }catch{
      return false
    }
  }

  SignOff(){
    this.localStorageService.clear()
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login')
    }, 500);
  }
}
