import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error(`Error al guardar en LocalStorage`, e);
    }
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key)!);
    } catch (e) {
      console.error(`Error al obtener datos en LocalStorage`, e);
      return null;
    }
  }

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(`Error al eliminar key en LocalStorage`, e);
    }
  }

  check(key: string): boolean {
    try {
      //TRUE: La variable existe y no es null
      //FALSE La variable no existe o es null
      const variable = localStorage.getItem(key)
      return (variable !== null)
       
    } catch (e) {
      return false
      console.error(`Error al comprobar key en LocalStorage`, e);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      console.error(`Error al limpiar o vaciar LocalStorage`, e);
    }
  }
}
