import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiGrupoCenturionService {

  private urlApi: string = environment.urlApiGrupoCenturion
  private readonly http = inject(HttpClient)

  private FullURL(endpoint:string): string {
    return `${this.urlApi}${endpoint}`
  }

  public get(path:string): any {
    return this.http.get(this.FullURL(path))
  }


  public getBlob(path:string): any {
    return this.http.get(this.FullURL(path), {responseType: 'blob'})
  }


  public post(path:string, data:any): any {
    return this.http.post(this.FullURL(path), data).pipe(
      catchError((error) => {
        
        console.log('Error capturado en ApiGrupoCenturionService')        
        console.error('ApiGCService -->',error);
        
        return throwError(() => error)
      })
    )    
  }

  public put(path:string, data:any): any {
    return this.http.put(this.FullURL(path), data).pipe(
      catchError((error) => {
        
        console.log('Error capturado en ApiGrupoCenturionService')        
        console.error('ApiGCService -->',error);
        
        return throwError(() => error)
      })
    )    
  }
}
